
.card-button button
{
    border: none;
    width: 100%;
    border-radius: 20px;
    padding: 5px 20px;
    background-color: #DEF2ED;
    font-size: 14px;
    font-weight: 500;
}
.card
{
    border: none !important;
    background-color: unset !important;
}

/* .main-container { 
    max-height:unset !important;
} */

.diagnosis-heading p
{
    font-size: 14px;
    font-weight: 400;
}

