

.doctor-profile-box{
    display: flex;
    // justify-content: space-between;
    // gap: 4vw;
    flex-direction: column;
    // justify-content: start;

    // .content{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: min(580px, 70vh) ;
        gap: .5vw;
        .formNav{
            background-color: rgba(255, 255, 255, .4);
            display: flex;
            flex-direction: column;
            gap: 5vh;
            border-radius: 1vw;
            padding: 2vh 1vw 2vh 4vw;
            margin-right: 2vw;
            .pageOption {
                display: flex;
                justify-content: flex-start;
                svg{
                    margin-right: 1vw;
                }
                background-color: transparent;
                border-width: 0px;
                padding: 0px 1vw 0px 2vw;
                font-size: .8rem;
                border-top-right-radius: 2vw;
                border-bottom-right-radius: 2vw;
                border-left-width: .25vw;
                border-color: transparent;
                &:hover{
                    border-color: black;
    
                }
            }
            .selected{
                background-color: rgba(255, 255, 255, .4);
                border-color: black;
            }

            img{
                height: 15vh;
                width: 15vh;
                border-radius: 50%;
            }
        }
    .form{
        border-radius: 1vw;
        background-color: rgba(255, 255, 255, .4);
        width: 55vw;
        height: 100%;
        display: flex;
        justify-content: center;
        .formHeading{
            display: flex;
            justify-content: space-between;
        
            // min-height: 500px;
            .formHeading{
                display: flex;
                width: 100%;
                padding: 1.5vw;
                justify-content: space-between;
                flex-direction: row;
            }
        }
        .btn{
            background-color: black;
            color: white;
            border-width: 0px;
            border-radius: 1vw;
            
        }
        .submit{
            width: 300px;
            height: 50px;
        }
    
        .edit{
            width: 100px;
            height: 50px;
        }
        .setting{
            width: 50%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            // align-items: center;
            height: 70%;

.setting-menu{
    width: 100%;
    #setect-option{
        width: 100%;
    }
    .setting-label {
    display: flex;
    .active{
        color: #4CAF50;
        flex-direction: row;
        font-weight: 800;
    }
    .deactive{
        color: red;
        font-weight:800;
    }
    }}
}}}
// }