.dropdown {
  position: relative;
  display: inline-block;

.menu {
  display: flex;
  align-items: center;
  // padding: 0vh 0vw 1vh 2vw;
  // gap: 1vw;
  .notification{
    img{
        width: 30px;
        height: 30px;
    }
  }
  .avatar {
    // border-radius: 50%;
    overflow: hidden;
    // border: 2px solid #ffff;
    img {
        height: max(30px,3.5vh) ;
      // background: transparent;
      // object-fit: cover;
      border-radius: 50%;
    }
  }
}

.dropbtn {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  margin-right: 4vw;
}



.dropdown-content {
  margin-right: 5vw;
  display: none;
  position: absolute;
  background-color: white;
  min-width: 8.8vw;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 3;
  padding: .5vh 0vw 3vh 0vw;
  border-radius: 1vh;

}

.dropdown-content button {
  color: black;
  padding: 1vh 1vw;
  text-decoration: none;
  display: block;
}



}
.dropdown-content a:hover {background-color: white; }

.dropdown:hover .dropdown-content {display: flex; gap: .5vh; flex-direction: column; justify-content: center; align-items: center;
  
  .chngBtn{
    background-color: black;
    color: lightgrey;
    border-radius: 1vh;
    font-size: .7rem;
  }
  a{
    text-decoration: none;
    color: black;  
  }
}
