@import "../../css/mixisin.scss";

/* .container {
    margin-top: 20px;
  } */
.before-profile-box{
  display: flex;
  flex-direction: row;
  gap: 5vw;
  width: 69vw;
  margin-top: 2vh;
    @include breakpoint-down(mobile) {
    flex-direction: column;
    }

  .scrollContainer::-webkit-scrollbar {
    width: 8px;   
  }
  
  .scrollContainer::-webkit-scrollbar-track {
    background:  white    /* color of the tracking area */
  }
  
  .scrollContainer::-webkit-scrollbar-thumb {
    background-color: #b07357; /* color of the scroll thumb */
    // border-radius: 20px;       /* roundness of the scroll thumb */
    // border: 3px solid ;  /* creates padding around scroll thumb */
  }
  .column {
    padding: 15px;
  }

  .user-photo {
    width: 100%;
    height: 50vh;
    max-width: 20vw;
    margin-bottom: 10px;
    border-radius: 2vw;
  }

  .profileDetail{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

  .box{
    display: flex;
    justify-content: space-evenly; 
    flex-direction: row;
    gap: 2vw;
    @include breakpoint-down(mobile) {
      flex-direction: column;
      }
  } 

  .profile-gap {
    background-color: #DEF2ED;
  }

  #pageTitle{
    display: flex;

  }

  .imgContainer{
    display: flex;
    flex-direction: row;
    gap: 2vw;
    cursor: pointer;
  }
  .scrollImage {
    // height: 10vh;
    width: 10vw;
    aspect-ratio: 208/235;
     margin-top: 10px;
    border-radius:  1.2vw;
  }
  .active-img{
    outline: 2px solid  #B17357;
    outline-offset: .1px;
  }

  .scrollContainer{
    display: flex;
    flex-direction: column;
    max-height: 75vh;
    height: max-content;
    max-width: 24.8vw;
    width: max-content;
    overflow-y: scroll;
    @include breakpoint-down(mobile) {
      width: 60vw;
      }
    justify-content: space-between;
    background-color: rgba(222, 242, 237, 1);
    border-radius: 1.2vw;
    padding: 1vh 2vw 1vh 1vw;

    .option{
        display: flex;
        
    }
  }

  .div-center{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h4{
    text-decoration: none;
    color: black;
  }

  .profileDetail{
    display: flex;
    flex-direction: column;
  }

  .photo-description{
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    min-width: 25vw;
    // gap: 10px;
  }
  
  .description{
    font-size: 1rem;
    span{
      font-weight: 500;
    }
  }

  .description-data{
    span{
    font-weight: 400;
      }  }
}