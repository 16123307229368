

table {
  width: 100%;
  margin-top: 20px;
  padding-right: 1vw;
  border-collapse: separate;
  border-spacing:0 2vh;
  z-index: 2;
  color: rgba(89, 113, 149, 1);
  font-size: 1rem;
}

th, td {
  text-align: left;
  /* background-color: transparent !important; */
}

/* th:first-child, td:first-child {
  width: 30%;
} */

th i {
  margin-right: 5px;
}
tbody tr td i
{
  padding: 5px 8px;
  border-radius: 50%;}
tr
{
  border-radius: 20px;
}

tbody, td, tfoot, th, thead, tr 
{
}

#table-head{
  background-color: transparent;
}