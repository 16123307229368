@media (min-width: 64rem) {
}

$breakpoints-up: (
  "tablet": "768px",
  "laptop": "1024px",
  "llaptop": "1440px",
  "desktop": "1920px",
);

$breakpoints-down: (
  "mobile": "767px",
  "tablet": "1024px",
  "laptop": "1440px",
);

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoints-up , $size)) {
    @content;
  }
}
@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoints-down , $size)) {
    @content;
  }
}



