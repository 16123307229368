@import "../../css/mixisin.scss";

.header {
  @include breakpoint-down(mobile){
    padding: 0px 1vw;
  }
  z-index: 2;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #ffff;
  padding-right: 1vw;
  box-sizing: border-box;
  height: 7.5vh;
  min-height: 50px;
  max-height: 80px;
  

  .logo-container {
    display: none;
    img {
      // object-fit: contain;
      height: max(50px,6vh) ;
    }
    @include breakpoint-up(tablet){
      min-width: 16.5vw;
      width: 20vw;
      max-width: 315px;
    }
    
    @include breakpoint-up(laptop){
      min-width: 16.5vw;
      width: 16.5vw;
      
      max-width: 315px;
    }
    @include breakpoint-up(tablet) {
      display: flex;
      align-items: center;
      justify-content: center;
      // display: block;
    }
  }
}
