@import "src/css/mixisin.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.transfer{
    margin-bottom: 25vh;
    position: relative;
}
.transferbutton{
  position: absolute;
  top:150px;
 
  @include breakpoint-down(tablet){
    top: 250px;
    }

}
.search-container{
    background-color: white;
    max-height: 50vh;
   z-index: 2;
    // width: 25vw;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;

    // padding: 1vw;
    h2{
        font-size: 1rem;
    }
    .search-card{
        align-items: center;
        padding-top: 1vh;
        padding-left: 1vw;
        border-radius: 10px;
    }
     .search-card:hover{
        //  border-bottom: 1px solid grey;
        background-color:  rgba(179, 213, 206, 1);
        
    }
}
.search-container::-webkit-scrollbar {
  width: 8px;   
}

.search-container::-webkit-scrollbar-track {
  background:  white    /* color of the tracking area */
}

.search-container::-webkit-scrollbar-thumb {
  background-color: #b07357; /* color of the scroll thumb */
  // border-radius: 20px;       /* roundness of the scroll thumb */
  // border: 3px solid ;  /* creates padding around scroll thumb */
}

.alert-box{
  font-family: 'Roboto';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color:  rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  dialog{
    border-radius: 5px;
    border-width: 0px;
    margin-bottom: 20vh;
    padding: 0px;
    width: 540px;

    h3{
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 0px;
    }

    #head{
      padding: 20px 20px 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #B5D7CF4D ;
    }

    #emailDetail{
      display: flex;
    }

    #content{
      padding: 20px 40px;
    }
    #cross-btn{
      border-width: 0px;
      border-radius: 50%;
      background-color: #B07357;
      color: white;
      font-weight: 500;
      // margin-bottom: 2px;
      font-size: 14px;
      // height: 20px;
      // width: 20px;
    }

    #close-btn{
      border-width: 0px;
      border-radius: 50%;
      background-color: #B07357;
      color: white;
      // height: 20px;
      // width: 20px;
    }
    #warning{
      display: block;
      color: #FF3D00;  
      font-size: 14px;
      font-weight: 400;
      margin-top: 30px;
      margin-bottom: 15px;
      text-align: center;
    }
    #dialog-btns{
      display: flex;
      justify-content: space-evenly;

      button{
        padding: 14px;
        border-width: 0px;
        width: 40%;
        border-radius: 10px;
      }

      #close-btn{
        background-color: #D9D9D9;
        color: #6D6D6D;
      }

      #continue-btn{
        background-color: #5F9D8D;
        color: white;
      }
    }
  }
}