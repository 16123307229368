@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.card
{
    display: flex;
    flex-direction: column;
    //  max-width: 235px;
  padding: 1vw;

    

    .name{
        font-size: .9rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h3{
       font-size: .7rem;
        margin: 0px;
        font-weight: 400;
        font-family: "Inter";
        font-size: .9rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .diagnosis-heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        // text-align: center;
        justify-content: space-between;
        width: 100%;
        // padding: 0px 1vw 0px 1vw;
    }
    img{
        // height: 100%;
        margin: 1vh 0vw;
        aspect-ratio: 208/235;
        width: 100%;
        border-radius: 1vw;
    }
    
    
    .card-button button
    {
        border: none;
        width: 100%;
        border-radius: 20px;
        padding: 5px 20px;
        background-color: #DEF2ED;
        font-size: .7rem;
        font-weight: 400;
    }
    
    .card-button{
        width: 100%;
    }
}