// @import "../../css/mixisin.scss";

// /* .container {
//   margin-top: 20px;
// } */

// .diagnosis-profile-box{
//   display: flex;
//   // justify-content: space-evenly; 
//   flex-direction: row;
//   gap: 2vw;
//   align-items: flex-start;
//   @include breakpoint-down(mobile) {
//     flex-direction: column;
//     }
// } 
//   .column {
//     padding: 15px;
//   }

//   .user-photo {
//     width: 100%;
//     height: 50vh;
//     margin-bottom: 10px;
//     border-radius: 2vw;
//   }

//   .graph{
//     height: 50vh;
//     width: 100%;
//   }




//   .paginationImage {
//     height: 15vh;
//     width: 100%;
//     aspect-ratio: 208/235;
//     margin-top: 2vh;
//     border-radius:  1.2vw;
//     min-width: 13.2vh;
//   }

.toggle-btn{
    background-color: transparent;
    border-width: 0px;
  }

//   #paginationContainer{
//     display: flex;
//     flex-direction: column;
//     min-height: 65vh;
//     height: fit-content;
//     gap: 3vh;
//     width: 40vw;
//     @include breakpoint-down(mobile) {
//       width: 60vw;
//       }
//       align-items: center;
//     justify-content: space-between;
//     background-color: rgba(222, 242, 237, 1);
//     border-radius: 1.2vw;
//     // padding: 1vh 1vw 1vh 1vw;
//   }

//   h4{
//     text-decoration: none;
//     color: black;
//   }

//   .profileDetail{
//     // margin-top: 5vh;
//     display: flex;
//     flex-direction: column;
//     gap: 2vh;
//     align-items: center;
//     justify-content: center;
//   }



.toggle-div{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: column;
  // width: 30vw;
  // z-index: 2;
  position: absolute;
  top:-50px;
  right: 10px;
  
}

  .photo-description{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    // width: 100%;
  }
  
  .description{
    font-size: 1rem;
  }

  .toggleCheckbox {
    display: none;
  }

  .toggleContainer {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // width: 18vw;
    border: 0px;
    border-radius: 20px;
    background: #EBEBE4;
    font-weight: bold;
    color: #343434;
    cursor: pointer;
 }

 .toggleContainer::before {
  content: '';
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0%;
  border-radius:20px;
  background:  #B17357;
  transition: all 0.3s;
}

.toggleContainer div {
  padding: 6px;
  text-align: center;
  z-index: 1;
}

.toggleCheckbox:checked + .toggleContainer::before {
  left: 50%;
}

.toggleCheckbox:checked + .toggleContainer div:first-child{
  color: #878783;
  transition: color 0.3s;
}
.toggleCheckbox:checked + .toggleContainer div:last-child{
  color: white; //#343434;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:first-child{
  color: white;// #343434;
  transition: color 0.3s;
}
.toggleCheckbox + .toggleContainer div:last-child{
  color: #878783;
  transition: color 0.3s;
}
