@import "../../css/mixisin.scss";

.page-link
{
    color: #000 !important;
}
.page-link.active
{
    background-color: #B17357;
    // border: none;
    color: #fff;
}

.nav-item{
    border: 0px;
    border-radius: 1vh;
    margin-right: .5vw;
    background-color: #fff;
    color: #000;
    width: 2vw;
    
    @include breakpoint-down(mobile){
        width: 7vw;
    }
    
    @include breakpoint-down(tablet){
        width: 7vw;
    }
}

.nav-active{
    background-color: #B17357;
    color: #fff;
}