.measure-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1vw;
  
  .heading-title{
     display: flex;
     width: 100%;
     flex-direction: row;
     // flex-wrap: wrap;
     align-items: center;
     justify-content: space-between;
     margin-bottom: 10px;
     gap: 1vw;
  }
 
      
 
  .name{
   font-size: .9rem;
 }
 
 h3{
  font-size: .7rem;
   margin: 0px;
   font-weight: 400;
   font-family: "Inter";
   
 }
  .photo-container{
   display: flex;
   width: 100%;
   justify-content: space-between;
   align-items: center;
    .photo {
      width: 48%;
      //height: 10vh;
      aspect-ratio: 180 / 190; 
      //max-width: 48%; /* Ensure there's some space between photos */
      margin-bottom: 10px;
      border-radius: 8px;
    }
  }
 
 
       .btn-view-profile {
     width: 100%;
     padding: 5px 20px;
     font-weight: 400;
     font-size: .7rem;
     border: none;
     border-radius: 8px;
     background-color: #DEF2ED;
     color: #000;
     cursor: pointer;
   }
 
   a{
     width: 60%;
   }
 
}