@import "../../css/mixisin.scss";
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: url('../../../public/images/rightside-container-background.png');
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid gray;
    border-radius: 10px;
    padding: 30px 5px;
    @include breakpoint-up(tablet) {
      padding: 80px;
    }
    button {
      margin-top: 5vh;
      // @include breakpoint-up(tablet) {
      //   margin-top: 0px;
      // }
    }
  }
}
