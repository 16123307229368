@import "./mixisin.scss";

.profile-box{
    display: flex;
    align-items: flex-start;
    // justify-content: space-evenly; 
    flex-direction: column;
    gap: 1vh;
    width: 75vw;
    // justify-content: space-between;
    // align-items: center;
    @include breakpoint-down(mobile) {
      flex-direction: column;
      }

      #pageHead{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 72vw;
        // margin-bottom: 1vh;
        #filter-images{
            background-color: transparent;
            font-size: 1.25rem;
            border-width: 0px;
        }
        .menu{
          gap: 1vw;
          width: max-content;

        }      
      }

      #nav-div{
        display: flex;
        // justify-content: space-between;
        align-items: flex-start;
        gap: 1vw;
        // margin-bottom: 0.5vh;
        width: 69vw;
        // margin-bottom: 1vh;
    .nav-btn{
        padding: 1vh 2vw;
        border-width: 0px;
        border-radius: 2vw;
        background-color: transparent;
    }  
    .nav-btn-active{
        background-color: #DEF2ED;
    }
    }
  
  .page-content{
    display: flex;
    flex-direction: column;
    gap: 4.5vh;
    justify-content: space-between;
  }

      #paginationContainer{
    display: flex;
  //  position: absolute;
    bottom: 0px;
    gap: 1vh;
    width: max-content;
    padding: 0vh 1vw;
    flex-direction: column;
    // min-height: 65vh;
    // height: fit-content;
    width: max-content;
    @include breakpoint-down(mobile) {
        width: 90vw;
      }
      @include breakpoint-down(laptop) {
        width: 70vw;
        overflow-x: scroll;
      }
      align-items: center;
      justify-content: space-between;
      background-color: rgba(222, 242, 237, 1);
      border-top-left-radius: 1.2vw;
      border-top-right-radius: 1.2vw;
      // padding: 1vh 1vw 1vh 1vw;


      
    .paginationImage {
          width: 6vw;
          aspect-ratio: 208/235;
          margin-top: 2vh;
          border-radius:  1.2vw;
          min-width: 13.2vh;
        }
    
        
        .pagination-scroll{
          gap: 1vw;
          display: flex;
          flex-direction: row;
        }
      }
      .active-img{
        outline: 2px solid  #B17357;
        outline-offset: .1px;
      }

.profile{
    display: flex;
    background-color: white;
    @include breakpoint-up(llaptop) {
      width: 75vw;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 2vw;
    }
    @include breakpoint-down(tablet) {
      width: fit-content;
      flex-direction: column;
    }
    border-radius: 2vw;
    gap: 2vw;
    position: relative;
    
    .user-photo {
    // width: 100%;
    height: 55vh;
    // margin-bottom: 10px;
    @include breakpoint-down(laptop) {
      max-width: 76vw;
      flex-direction: column;
    }
    border-radius: 2vw;
    cursor: pointer;
    }
    
    .graph{
    height: 52vh;
    cursor: pointer;

    // width: 100%;
    }
    .detail{
        margin-top: 1vh;
        display: flex;
        flex-direction: column;
        width: max-content;
        width: 55vh;

        @include breakpoint-down(laptop) {
          // overflow-y: scroll;
          margin-left: 2vw;
          margin-bottom: 2vw;
        }
        .prescription{
          height: max-content;
          width: max(20vw, 300px);
          background-color: rgba(222, 242, 237, 1);
          border-radius: 5px;
          padding: 10px 20px ;
        }
        .profileDetail{
            display: flex;
            flex-direction: column;
            gap: 2vw;
            // align-items: center;
            // justify-content: center;
            // width: 20vw;
        }
        .photo-description{
            display: flex;
            flex-direction: row;
            // justify-content: center;
            gap: 1vw;
            // width: 100%;
        
        }
        .description{
          font-size: 1rem;
          padding-top: 5px;
        }
        .description-data{
            font-weight: 500;
        }
        #filter{
            width: 18vw;
            background-color: #DEF2ED;
            size: 1rem;
            padding: 1vh 0.5vh;
            border-radius: 10px;
            border-width: 0px;
            margin-bottom: 2vh;
        }
    }

}

} 