@import "./mixisin.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

a{
  text-decoration: none;
}



.main-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  // justify-content: center;
  background: url("../../public/images/rightside-container-background.png");
  background-size: cover;
  background-color: black;
  // padding-top: 10vh;
  font-family: "Inter";;
  padding: 13vh 5vw 0vh 5vw;
  // padding-top: 16.5vh;
  // min-height: 92vh;
  #pageTitle{
    display: flex;
    align-items: center;
    gap: 1vw;
    // margin-bottom: 1.5vw;
    width: 69vw;;
  }
  .back-btn{
    border-radius: 50%;
    border-width: 0px;
    // width: max-content;
    min-height: 2.5vw;
    min-width: 2.5vw;
    background-color:  rgba(255, 255, 255, .2);
    a{
      color: black;
    }
  }
  
  @include breakpoint-down(mobile){
    width: 100vw;

  }

  @include breakpoint-up(tablet){
    margin-left: 16.5vw;
    min-width: 82vw;
    }
    
    @include breakpoint-up(laptop){
      margin-left: 16.5vw;
      min-width: 82vw;
    }
  // width: max-content;
  min-height: 100vh;

  .search{
    margin-bottom: 2vh;

    .search-label{
      font-family: "Inter";
      font-weight: 400;
      font-size: 1.25rem;
      padding-bottom: 1vh;
    }
  }

  // @include breakpoint-up(laptop) {
  //   margin-left: 13.5vw;
  //   padding: 13vh 2.5vw 13vh 2.5vw;
  // }

  // box-sizing: border-box;
  // padding-bottom: 10vh;

  // @include breakpoint-down(tablet) {
  //   margin-left: 16.5vw;
  //   // align-items: normal;
  //   padding-top: 13vh;
  // }

  // @include breakpoint-down(mobile) {
  //   font-size: 2vh;
  //   margin-left: 0vw;
  //   padding-top: 10vh;
  // }


  .page-nav {
    // z-index: 2;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    margin: 2vh;

    @include breakpoint-down(mobile) {
      bottom: 2vh;
      width: 80vw;
      height: 5vh;
    }

    @include breakpoint-down(tablet) {
      bottom: 2vh;
      width: 50vw;
      height: 5vh;
    }

    table {
      color: rgba(89, 113, 149, 1);
    }
  }

}


  
  .sidebar {
    margin-top: min(7.5vh, 80px) ;
    left: 0;
    display: flex;
    // flex: 1;
    z-index: 1;
    justify-content: space-between;
    flex-direction: column;
  background-size: cover;
    
    position: fixed;
    // max-width: 20vw;
    // @include breakpoint-up(tablet) {
      //   position: fixed;
      //   width: 50vw;
      // }
      @include breakpoint-up(tablet){
      min-width: 16.5vw;
      width: 20vw;
      max-width: 315px;
      }
      
      @include breakpoint-up(laptop){
        min-width: 16.5vw;
        width: 16.5vw;

        max-width: 315px;
      }

      @include breakpoint-down(mobile){
        width: 60vw;
      }
    min-height: 93.5vh;
    // max-height: 100vh;
    box-sizing: border-box;
    // padding: 50px 0px;
    // border: 1px solid #000000;
    color: black;
    background: url("../../public/images/sidebar-background.png");
    
    // z-index: 1;
    font-size: 0.8rem;
    
    &.inactive {
      display: none;
      @include breakpoint-up(tablet) {
        display: flex;
      }
    }
    .close-button {
      position: absolute;
      right: 0;
      top: 0;
      border: none;
      background: transparent;
      img {
        background: transparent;
        height: 40px;
        width: 40px;
      }
      @include breakpoint-up(tablet) {
        display: none;
      }
    }
    .user-panel {
      display: flex;
      flex-direction: column;
      
      @include breakpoint-up(tablet){
        margin: 1vw;
        padding-top: 6.5vh;
      }
      @include breakpoint-down(mobile){
        padding-top: 3.5vh;
        margin: 5vw;
      }
      
      gap: .5vw;
      
      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        font-family: "Inter";
        
        @include breakpoint-down(mobile){
          padding: 1.2vh 5vw 1.2vh 5vw;
        }
        padding: 1.2vh 2vw 1.2vh 1vw;
        
        color: black;
        // border-radius: 8px;
        gap: 1rem;
        box-sizing: border-box;
        font-weight: 400;
        font-size: .9rem;
        line-height: 1rem;
        position: relative;
        svg {
          path {
            stroke: black;
          }
        }
        &:hover {
          &::after {
            content: "";
            background: black;
            position: absolute;
            top: 50%;
            left: 0;
            height: 100%;
            width: 5px;
            border-radius: 5px;
            transform: translate(0, -50%);
          }
        }
        
        &.active {
          &::after {
            content: "";
            background: black;
            position: absolute;
            top: 50%;
            left: 0;
            height: 100%;
            width: 5px;
            border-radius: 5px;
            transform: translate(0, -50%);
          }
        }
      }
    }
    .logout-section {
      margin: 20px;
      
      button {
        border: none;
        text-align: left;
        background: transparent;
        color: black;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        
        svg {
          path {
            stroke: black;
          }
        }
        &:hover {
          color: black;
          svg {
            path {
              stroke: black;
            }
          }
        }
      }
    }
  }
  
.loader{
height: 60vh;
display: flex;
justify-content: center;
align-items: center;
padding-bottom: 20vh;
}
  
.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// .right-side-container {
//   display: flex;
//   align-items: center;
//   flex-direction: column;
//   margin: 40px 0px;

//   @include breakpoint-up(laptop) {
//     margin: 20px;
//     flex: 4;
//   }
// }

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;

  @include breakpoint-down(tablet) {
    margin: 50px;
    gap: 30px;
  }

  @include breakpoint-down(laptop) {
    margin: 80px;
    gap: 30px;
  }

  @include breakpoint-down(llaptop) {
    margin: 100px;
    gap: 30px;
  }

  span {
    color: red;
  }

  .form-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint-up(laptop) {
      flex-direction: row;
      justify-content: space-between;
      gap: 40px;
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;

    label {
      text-align: left;
      margin-bottom: 10px;
      margin-top: 16px;
    }

    input {
      height: 50px;
      width: 300px;
      border-radius: 10px;
      border: 2px solid #ffff;
      padding: 0px 30px;
      box-sizing: border-box;
      color: black;
      font-size: 16px;

      &::placeholder {
        color: black;
      }

      &.error {
        border-color: red;
      }

      @include breakpoint-up(tablet) {
        height: 60px;
        width: 400px;
      }
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      transform: scale(1.5);
      color: rgba(0, 0, 0, 0);
      cursor: pointer;

      &:disabled {
        filter: invert(1);
      }
    }

    input:disabled {
      cursor: not-allowed;
    }
  }

  button {
    margin-top: 20px;
    height: 50px;
    padding: 10px 30px;
    background: black;
    color: #ffff;
    border-radius: 5px;
    border: none;
    width: 200px;
    font-size: 16px;

    &:disabled {
      cursor: not-allowed;
    }
  }
}



.filter {
  position: relative;

  .menu {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0vh 2vw;
    width: auto;
    background-color: white;
    height: auto;
    border-radius: 1vh;
    
    span {
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
    }

  }

  .dropbtn {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }
  
  .filter-content {
    padding: 5px 0px;
    display: none;
    position: absolute;
    background-color: white;
    /* min-width: 10vw; */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    background-color: transparent;
    display: flex;
    gap: .5vh;
    width: 100%;
    flex-direction: column;
  
  align-items: center;
  }
  
  .filter-content button {
    padding: 1vh 1vw;
    margin: 0px;
    text-decoration: none;
    display: block;
    text-align: right;
    border-radius: 1vh;
    border-width: 0px;

  }
  
  
  
  
}
.none-btn{
  background-color: #D9D9D9;
  color: #6D6D6D;
}
.btn-unactive{
  background-color: rgba(179, 213, 206, .5);
}
.btn-active {
  background-color: rgba(179, 213, 206, 1);
}
// .filter-content a:hover {
//   background-color: white;
// }

// .filter:hover .filter-content {
//   display: flex;
//   gap: .5vh;
//   width: 100%;
//   flex-direction: column;
  
//   align-items: center;
// }

.applyBtn {
  background-color: black;
  color: lightgrey;
  border-radius: 1vh;
  font-size: 1rem;
}

.filterForm {
  margin: 1vh 1vw;
  border-radius: 1vh;
  display: flex;
  flex-direction: column;
  padding: 5vh 4vw 3vh 5vw;
  gap: 1vh;
  width: 100%;
  padding: 30px 20px;
  background-color: white;
}

.filterInput {
  display: flex;
  justify-content: space-between;
  width: 100%;
}