// @import "../../css/mixisin.scss";
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// .measure-profile-box{
//   display: flex;
//   flex-direction: row;
//   gap: 5vw;
//   @include breakpoint-down(mobile) {
//     flex-direction: column;
//     }

//   .scrollContainer::-webkit-scrollbar {
//     width: 8px;   
//   }
  
// .content{
//   display: flex;
//   flex-direction: row;
// }

//   .scrollContainer::-webkit-scrollbar-track {
//     background:  white    /* color of the tracking area */
//   }
  
//   .scrollContainer::-webkit-scrollbar-thumb {
//     background-color: #b07357; /* color of the scroll thumb */
//     // border-radius: 20px;       /* roundness of the scroll thumb */
//     // border: 3px solid ;  /* creates padding around scroll thumb */
//   }

//   .column {
//     padding: 15px;
//   }

//   .user-photo {
//     width: 100%;
//     height: 50vh;
//     max-width: 20vw;
//     margin-bottom: 10px;
//     border-radius: 2vw;
//   }

//   #pageTitle{
//     display: flex;
//     width: 70vh;
//   }

//   .imgContainer{
//     display: flex;
//     flex-direction: row;
//     gap: 2vw;
    
//     .scrollImage {
//       // height: 10vh;
//       width: 10vw;
//       aspect-ratio: 208/235;
//       margin-top: 2vh;
//       border-radius:  1.2vw;
//     }
    
//     .scrollContainer{
//       display: flex;
//       flex-direction: column;
//       height: 75vh;
//       width: 24.8vw;
//       overflow-y: scroll;
//       @include breakpoint-down(mobile) {
//         width: 60vw;
//       }
//       justify-content: space-between;
//       background-color: rgba(222, 242, 237, 1);
//       border-radius: 1.2vw;
//       padding: 1vh 2vw 0vh 2vw;
      
//       .option{
//         display: flex;
        
//       }
//     }
    
//     h4{
//       text-decoration: none;
//       color: black;
//     }  
//   }

//     .profileDetail{
//       display: flex;
//       flex-direction: column;
//       align-items: flex-start;
//   }
  
//   .photo-description{
//     p{
//     font-family: "Roboto";
//     font-weight: 400;
//     font-size: 1rem;
//     }
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     width: 20vw;
//     .data{
//       p{
//       font-weight: 500;}
//     }
//   }
  
// }

.graphBox{
    background-color: white;
    width: 100%;
    height: fit-content;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
}

.graph{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    min-width: 25%;
    margin: 10px 0px;
}