@import "../../css/mixisin.scss";
.sidebarhum {
  @include breakpoint-up(tablet) {
    display: none;
  }
  button{
    border: none;
    background: transparent;
    img{
      height: 40px;
      width: 40px;
      object-fit: cover;
    }
  }
}
