.record{
    background-color: rgba(255, 255, 255, .2);
 position: relative;
 height:75px;
}
.record::after{
    position: absolute;
    content: '';
}
.infoButton{
    /* position: absolute; */
    /* z-index: 1; */
    background-color: rgba(255, 255, 255, .2);
    border: 0px;
    border-radius: 50%;
    /* right: -1vw; */
    /* top: 3vh; */
}

.btn-div{
    position: absolute;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75px;
    width: 20px;
}

.infoIcon{
    font-size: 1.5rem;

}

td:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

td:nth-last-child(2){

    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}