 /* #app {
    height: 100%;
  }
  html,
  body {
    position: absolute;
    height: 100%;
  } */
  
  /* body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  } */
  
  .swiper {
    width: 97vw;
    height: 98vh;
    z-index: 2;
    position: absolute;
    top: 1vh;
    left: 1vw;

    #close-button{
        position: absolute;
        background-color: red;
        color: white;
        font-size: 1.5rem;
        font-weight: 800;
        border-width: 0px;
        border-color: white;
        border-radius: 50%;
        top:10px;
        right: 10px;
        /* z-index: 2; */
    }
}
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: rgba(0, 0, 0, .5);
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    /* width: 100%; */
    height: 100%;
    object-fit: cover;
  }

  .swiper-button-prev{
    color: white;
  }
  .swiper-button-next{
    color: white;
  } 


  
