@import "src/css/mixisin.scss";


.reportResult{
    display: flex;
    flex-wrap: wrap;
}

.date-report{
  margin-bottom: 25vh;
  position: relative;

}

.downloadbutton{
  position: absolute;
  top:150px;
 
  @include breakpoint-down(tablet){
    top: 250px;
    }

}


form{
    display: flex;
    flex-direction: row;
}

