

table {
    width: 100%;
    margin-top: 20px;
    border-collapse: separate;
    border-spacing:0 2vh;
    z-index: 2;
}

th, td {
    padding: 10px !important;
    text-align: left;
    /* background-color: transparent !important; */
}

th:first-child, td:first-child {
    width: 5%;
}

th i {
    margin-right: 5px;
}
tbody tr td i
{
    padding: 5px 8px;
    border-radius: 50%;}
tr
{
    border-radius: 20px;
}


#table-head{
    background-color: transparent;
  }